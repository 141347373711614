/* global Craft */

export function t(message, params) {
    if (
        typeof Craft != 'undefined' &&
        typeof Craft.translations['site'] !== 'undefined' &&
        typeof Craft.translations['site'][message] !== 'undefined'
    ) {
        message = Craft.translations['site'][message];
    }

    if (params) {
        for (const key in params) {
            if (!params.hasOwnProperty(key)) {
                continue;
            }

            message = message.replace('{' + key + '}', params[key]);
        }
    }

    return message;
}